export default {
  version: "2.6.0",
  baseUrl: "https://staging-api.trees4trees.org/api/",
  baseUrlUpload: "https://geko-asset.t4t-api.org",
  baseUrlExport: "https://geko-export.t4t-api.org/",
  baseUrlNursery: "https://api-nursery.t4t-api.org/api",
  mapBoxApi:
    "pk.eyJ1IjoiZGV2dDR0IiwiYSI6ImNtMm1zc3M0NDBxeXcyanFzd25ndnlva3cifQ.pBINatrH39ajJ_ILIHoGsg",
  mapBoxStyle: "mapbox://styles/mapbox/satellite-streets-v12",
  programYear: {
    model: "2024",
    options: ["2020", "2021", "2022", "2023", "2024"],
  },
};
